exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ansprechpartner-js": () => import("./../../../src/pages/ansprechpartner.js" /* webpackChunkName: "component---src-pages-ansprechpartner-js" */),
  "component---src-pages-catering-10-js": () => import("./../../../src/pages/catering-10.js" /* webpackChunkName: "component---src-pages-catering-10-js" */),
  "component---src-pages-catering-30-js": () => import("./../../../src/pages/catering-30.js" /* webpackChunkName: "component---src-pages-catering-30-js" */),
  "component---src-pages-danke-gutschein-js": () => import("./../../../src/pages/danke-gutschein.js" /* webpackChunkName: "component---src-pages-danke-gutschein-js" */),
  "component---src-pages-danke-kontakt-js": () => import("./../../../src/pages/danke-kontakt.js" /* webpackChunkName: "component---src-pages-danke-kontakt-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-geburtstagsfeiern-js": () => import("./../../../src/pages/geburtstagsfeiern.js" /* webpackChunkName: "component---src-pages-geburtstagsfeiern-js" */),
  "component---src-pages-gourmetkueche-js": () => import("./../../../src/pages/gourmetkueche.js" /* webpackChunkName: "component---src-pages-gourmetkueche-js" */),
  "component---src-pages-gutscheine-js": () => import("./../../../src/pages/gutscheine.js" /* webpackChunkName: "component---src-pages-gutscheine-js" */),
  "component---src-pages-hochzeitsfeiern-js": () => import("./../../../src/pages/hochzeitsfeiern.js" /* webpackChunkName: "component---src-pages-hochzeitsfeiern-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-newsletter-anmeldung-js": () => import("./../../../src/pages/newsletter-anmeldung.js" /* webpackChunkName: "component---src-pages-newsletter-anmeldung-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-ueberuns-js": () => import("./../../../src/pages/ueberuns.js" /* webpackChunkName: "component---src-pages-ueberuns-js" */)
}

